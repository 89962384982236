import React from "react"
import Header from "../components/header"
import { graphql } from "gatsby"
import { Nav } from "../components/navBar"
import * as styles from "./postPageStyles.module.scss"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "LL")
      }
    }
  }
`

export default function postPage({ data }) {
  const {
    markdownRemark: {
      frontmatter: { title },
    },
  } = data
  const {
    markdownRemark: {
      frontmatter: { date },
    },
  } = data
  const {
    markdownRemark: { html },
  } = data

  const titleWithSpaces = title.replace(/-/g, " ")

  return (
    <div>
      <>
        <Header> </Header>
        <div className={styles.layout}>
          <div className={styles.singlePost}>
            <h2 className={styles.singlePostTitle}>{titleWithSpaces}</h2>
            <p className={styles.singlePostDate}>{date}</p>
            <div
              className="not-defined-yet"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          <Nav className={styles.navLayoutPostPage}> </Nav>
        </div>
      </>
    </div>
  )
}
